<template>
  <div class="logoComponent">
    <router-link to="/">
      <img id='logo-img' :src="require('@/assets/images/eclerck_logo.png')" alt="Eclerk">
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'logoComponent'
}
</script>

<style>
#logo-img {
  border-radius: 15px;
  height: 70px;
}
</style>
